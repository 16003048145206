// Here you can add other styles
.btn-outline-warning {
    color: #ec971f;
    background-color: transparent;
    background-image: none;
    border-color: #ec971f;

    &:hover {
        color: #fff;
        background-color: #ec971f;
    }

    &.focus, &:focus {
        color: #fff !important;
        background-color: #ec971f !important;
    }

    &.active, &:active {
        color: #fff !important;
        background-color: #ec971f !important;
    }
}

.floating-loading .loading {
    position: absolute;
    z-index: 50;
    left: 50%;

    .sk-circle::before{
        box-shadow: rgba(0,0,0, .5) 0 0 30px, #ffffff 0 0 20px, #ffffff 0 0 20px, #ffffff 0 0 20px, #ffffff 0 0 20px;
    }
}

.DateRangePicker_picker{
    z-index: 999 !important;
}

@media screen and (min-width: 1401px) {
  h3.responsive-label-text {
    font-size: 28px;
  }
}

@media screen and (max-width: 1400px) {
  h3.responsive-label-text {
    font-size: 3vw;
  }
}

@media screen and (min-width: 751px) {
  .responsive-label-text {
    font-size: 18px;
  }
}

@media screen and (max-width: 750px) {
  .responsive-label-text {
    font-size: 3.5vw;
  }
}

.toast_container {
  z-index: 4000;
}


.login-tag {
  display: inline-block;
  vertical-align: middle;
  > * {
    line-height: 1.2em;
  }

  > .institution {
    font-size: .77em;
  }

}

.productIcon-tooltip-arrow {
  border-top-color: #20a8d8;
  border-left-color: #20a8d8;
  border-right-color: #20a8d8;
  border-bottom-color: #20a8d8;
}

.productIcon-tooltip-inner {
  background: #20a8d8;
  color: whitesmoke;
}

.productIcon-product-not-active {
  filter: gray; /* IE6-9 */
  -webkit-filter: grayscale(1); /* Google Chrome, Safari 6+ & Opera 15+ */
  filter: grayscale(1); /* Microsoft Edge and Firefox 35+ */
}


.loader-container {
  position: relative;
  
  > .loading {
    position: absolute;
    z-index: 100;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background: rgba(0, 0, 0, .25);
    display: flex;
    justify-content: center;
    align-items: center;
    > .sk-fading-circle {
      transform: translateY(-40px);
    }
  }
}


//Top bar
.topBar {
  display: flex;
  width: 98%;
  @media (max-width: 1100px) {
      display: inherit;
      .header-right {
          margin-left: -20px;
          margin-bottom: -20px;
          margin-top: 15px;
      }
  }
  @media (max-width: 500px) {
      display: flex;
      flex-direction: column-reverse;
      .header-right {
          margin-left: -20px;
          margin-bottom: -20px;
      }
  }
}

.header-right {
  margin-left: auto;
  display: flex;
  > * {
      margin-left: 20px;
  }
  @media (max-width: 550px) {
      display: block;
  }
}

.date-range-picker {
  border: 1px solid #1d78db;
  border-radius: 5px;
  width: 280px;
  z-index: 1;
  height: 46px;
}

.form-control.date-picker {
  font-size: 17px;
  height: 43px;
  border: none;
  border-left: 1px solid #1d78db;
}

.buttons {
  @media (max-width: 500px) {
      margin-bottom: 0px;
  }
}


.statstable {
    td, th {
      text-align: center;
    }
}


.three-overlay {
    position: absolute;
    top: 2px;
    color: white;
    left: 20px;
}