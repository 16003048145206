



.akc-icon {
    display: inline-block;
    height: 1em;
    width: 1em;
    background-repeat: no-repeat;
    background-position: center center;

    &.shelfos {
        background-image: url(/assets/img/icons/shelfos.png);
        background-size: 1em 1em;
    }

    &.product-events {
        background-image: url(/assets/img/icons/product_events.png#9);
        background-size: 1em 1em;
    }

}

.big-icon {
    font-size: 128px;
}

.big-icon-holder {
    width: 200px;
    flex-grow: 0;
    flex-basis: 200px;
}