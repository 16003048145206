.user-tag {
    display: inline-block;

    padding: 8px;

    &.no-padding {
        padding: 0;
    }

    > * {
        display: inline-block;
        vertical-align: middle;
    }
    > .img-avatar {
        height: 32px;
    }
    > .user-tag-info {
        margin-left: 8px;
        margin-right: 8px;

        > * {
            line-height: 1.2em;
        }
      
        > .institution {
            font-size: .77em;
        }
    }
}
  